<template>
  <div class="highlights-container">
    <h1
      class="primary-text fw-boldest d-inline-block pb-3"
      style="border-bottom: 2px solid #d5dfe5"
    >
      Highlights
    </h1>
    <ul
      class="primary-text fw-bold mb-0"
      style="font-size: 1.35em"
      v-loading="loading"
    >
      <li
        class="mt-10"
        v-for="highlight in projectHighlights"
        :key="highlight.id"
      >
        {{ highlight.value }}
      </li>
    </ul>
    <div
      style="height: 10rem"
      v-if="projectHighlights.length === 0"
      class="d-flex justify-content-center align-items-center w-100"
    >
      <h4 class="primary-text">This user hasn't not added highlights</h4>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import { getProjectHighlights } from "@/api/founder.api";

export default {
  name: "PublicPitchHighlights",
  props: {
    founderId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const projectHighlights = ref([]);
    const loading = ref(false);

    onMounted(async () => {
      try {
        loading.value = true;
        await fetchProjectHighlights();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error fetching data.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    });

    const fetchProjectHighlights = async () => {
      const { data } = await getProjectHighlights(props.founderId);
      projectHighlights.value = data.data;
    };

    return { loading, projectHighlights };
  },
};
</script>

<style scoped>
.highlights-container {
  /*margin-top: 3rem;*/
  margin-left: 1rem;
  /*height: 1rem !important;*/
}
</style>
