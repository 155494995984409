<template>
  <el-row :gutter="40" v-loading="loading">
    <el-col :span="18">
      <el-row class="mt-5 h-100">
        <el-col :span="24" class="h-100">
          <el-image
            v-if="teaserImage"
            class="w-100 h-100"
            style="border-radius: 10px"
            :src="teaserImage"
          ></el-image>
          <div
            v-else
            class="d-flex justify-content-center align-items-center w-100 h-100"
          >
            <h4 class="primary-text">
              This user hasn't not added teaser image
            </h4>
          </div>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="6">
      <el-row class="mt-5">
        <el-col :span="24">
          <div class="d-flex flex-column justify-content-between h-100">
            <div>
              <div class="mb-8">
                <h3 class="stat-heading">$0</h3>
                <p class="stat-description">3'008% of minimum goal raised</p>
              </div>
              <div class="mb-8">
                <h3 class="stat-heading">0</h3>
                <p class="stat-description">Investors</p>
              </div>
              <div class="mb-8">
                <h3 class="stat-heading">90 days</h3>
                <p class="stat-description">Left to invest</p>
              </div>
            </div>
            <el-button class="w-100" type="primary" @click="dialogVisible = true"
              >Invest in {{ companyName }}</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
  <el-row :gutter="40" v-loading="loading">
    <el-col :span="18">
      <el-row class="mt-15">
        <el-col :span="24">
          <PublicPitchHighlights :founder-id="founderId" />
        </el-col>
      </el-row>
      <el-row class="mt-15">
        <el-col :span="24">
          <PublicPitchDocumentViewer
            :pitch-file="pitchFile"
            :founder-id="founderId"
          />
        </el-col>
      </el-row>
      <el-row class="mt-15">
        <el-col :span="24">
          <PublicPitchNeeds :founder-id="founderId" />
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="6">
      <el-row class="mt-15">
        <el-col :span="24">
          <PublicPitchDealTerms :founder-id="founderId" />
        </el-col>
      </el-row>
      <el-row class="mt-15">
        <el-col :span="24">
          <PublicPitchDocuments
            :documents="documents"
            :founder-id="founderId"
          />
        </el-col>
      </el-row>
      <el-row class="mt-15">
        <el-col :span="24">
          <PublicPitchNeedShare :founder-id="founderId" />
        </el-col>
      </el-row>
    </el-col>
  </el-row>
  <!--  <el-row :gutter="40">-->
  <!--    <el-col :span="18" style="height: 15rem">-->
  <!--      <PublicPitchHighlights :founder-id="founderId" />-->
  <!--    </el-col>-->
  <!--    <el-col :span="6" class="public-pitch-deal-terms">-->
  <!--      <PublicPitchDealTerms :founder-id="founderId" />-->
  <!--    </el-col>-->
  <!--  </el-row>-->
  <!--  <el-row :gutter="40">-->
  <!--    <el-col :span="18">-->
  <!--      <PublicPitchDocumentViewer :founder-id="founderId" />-->
  <!--    </el-col>-->
  <!--    <el-col :span="6">-->
  <!--      <PublicPitchDocuments :founder-id="founderId" />-->
  <!--    </el-col>-->
  <!--  </el-row>-->
  <!--  <el-row class="mt-10" :gutter="40">-->
  <!--    <el-col :span="18">-->
  <!--      <PublicPitchNeeds :founder-id="founderId" />-->
  <!--    </el-col>-->
  <!--    <el-col :span="6">-->
  <!--      <PublicPitchNeedShare :founder-id="founderId" />-->
  <!--    </el-col>-->
  <!--  </el-row>-->
<FounderInvestmentDialogue
    :showDialogue="dialogVisible"
    @closeDialogue="closeDialogue"
    :companyName="companyName"
    :founder-id="founderId"
/>
</template>

<script>
import PublicPitchHighlights from "@/founder/components/PublicPitchHighlights";
import PublicPitchDealTerms from "@/founder/components/PublicPitchDealTerms";
import PublicPitchDocumentViewer from "@/founder/components/PublicPitchDocumentViewer";
import PublicPitchDocuments from "@/founder/components/PublicPitchDocuments";
import PublicPitchNeeds from "@/founder/components/PublicPitchNeeds";
import PublicPitchNeedShare from "@/founder/components/PublicPitchNeedShare";
import FounderInvestmentDialogue from "@/founder/components/FounderInvestmentDialogue";
import {
  getAllRequiredUploads,
  getFounderCompanyName,
  getLogos,
} from "@/api/founder.api";
import { onMounted, ref } from "vue";
export default {
  name: "PublicPitch",
  components: {
    PublicPitchNeedShare,
    PublicPitchNeeds,
    PublicPitchDocuments,
    PublicPitchDocumentViewer,
    PublicPitchDealTerms,
    PublicPitchHighlights,
    FounderInvestmentDialogue
  },
  props: {
    founderId: {
      type: Number,
      required: true,
    },
  },

  setup(props, context) {
    const companyName = ref(null);
    const teaserImage = ref(null);
    const loading = ref(false);
    const documents = ref([]);
    const pitchFile = ref(null);
    const dialogVisible = ref(false)

    onMounted(async () => {
      loading.value = true;
      await fetchCompanyName();
      await fetchLogos();
      await fetchDocuments();
      loading.value = false;
    });

    const fetchDocuments = async () => {
      const { data } = await getAllRequiredUploads(props.founderId);
      if (data.data.venturePitch) {
        let venturePitchFile = await fetch(data.data.venturePitch);
        venturePitchFile = await venturePitchFile.blob();
        pitchFile.value = URL.createObjectURL(venturePitchFile);
      }
      documents.value.push({
        type: "venturePitch",
        file: data.data.venturePitch,
        name: "Venture Pitch",
      });
      documents.value.push({
        type: "financialPlan",
        file: data.data.financialPlan,
        name: "Financial Plan",
      });
      documents.value.push({
        type: "sevenFoundersForecast",
        file: data.data.sevenFoundersForecast,
        name: "Forecast",
      });
      documents.value.push({
        type: "dataRequest",
        file: data.data.dataRequest,
        name: "Data Request",
      });
    };

    const fetchCompanyName = async () => {
      const { data } = await getFounderCompanyName(props.founderId);
      companyName.value = data.data.companyName;
    };

    const fetchLogos = async () => {
      const { data } = await getLogos(props.founderId);
      teaserImage.value = data.data.teaserImage;
    };
    const closeDialogue = (value) => {
      dialogVisible.value = value;
    };

    return { companyName, teaserImage, loading, documents, pitchFile, dialogVisible, closeDialogue
    };
  },
};
</script>

<style scoped>
.public-pitch-deal-terms {
  /*height: 15rem;*/
  /*overflow: scroll;*/
}
.stat-heading {
  color: #4dc75f;
  font-size: 3em;
}
.stat-description {
  color: #a0b7c3;
}
</style>
