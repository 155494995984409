<template>
  <el-dialog
    v-model="dialogVisible"
    :title="'Invest in ' + companyName"
    width="30%"
    :before-close="handleClose"
    center
  >
    <el-form v-loading="loading" ref="form" class="el-form-style">
      <el-form-item>
        <el-select
          v-model="needID"
          @change="selectProjectNeed"
          clearable
          placeholder="SELECT A NEED"
          size="large"
          style="width: 100%"
        >
          <el-option
            v-for="(project, index) in projectNeeds"
            :key="index"
            :label="project.name"
            :value="project.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="discountPercentage"
          clearable
          placeholder="YOUR SMALL CAPITAL OFFER"
          size="large"
          style="width: 100%"
          :disabled="needID === null"
        >
          <el-option
            v-for="(discount, index) in discountPercentageArray"
            :key="index"
            :label="projectBudget + '-' + discount + '%'"
            :value="discount"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="message"
          type="textarea"
          placeholder="SEND A MESSAGE TO THE FOUNDERS"
          row="10"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button :disabled="loading" type="primary" @click="submitInvest">
          Invest in {{ companyName }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { onMounted, watch, ref } from "vue";
import { ElNotification } from "element-plus";
import {
  getAllProjectNeedsOfProject,
  investInProject,
} from "@/api/founder.api";
export default {
  name: "FounderInvestmentDialogue",
  props: {
    showDialogue: {
      default: false,
    },
    companyName: {
      type: String,
    },
    founderId: {
      type: Number,
      required: true,
    },
  },
  emits: ["closeDialogue"],
  setup(props, ctx) {
    const dialogVisible = ref(props.showDialogue);
    const projectNeeds = ref([]);
    const loading = ref(false);
    const handleClose = (val) => {
      ctx.emit("closeDialogue", false);
    };
    const needID = ref(null);
    const discountPercentageArray = ref([
      25, 50, 75, 100, 150, 200, 300, 400, 500,
    ]);
    const discountPercentage = ref(null);
    const projectBudget = ref(null);
    const message = ref(null);
    const fetchProjectNeeds = async () => {
      try {
        loading.value = true;
        const { data } = await getAllProjectNeedsOfProject(props.founderId);
        projectNeeds.value = data.data;
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error fetching data.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };
    const selectProjectNeed = () => {
      const project = projectNeeds.value.find(
        (project) => project.id === needID.value
      );
      projectBudget.value = project.budget;
    };
    watch(
      () => props.showDialogue,
      (value) => {
        dialogVisible.value = value;
        if (props.showDialogue === true) {
          fetchProjectNeeds();
        }
      },
      {
        immediate: true,
      }
    );
    const submitInvest = async () => {
      try {
        if (needID.value === null || discountPercentage.value === null) {
          ElNotification({
            title: "Error",
            message: "Please Select Need and Offer first.",
            type: "error",
          });
        } else {
          loading.value = true;
          await investInProject({
            needId: needID.value,
            discountPercentage: discountPercentage.value,
            message: message.value,
          });
        }
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error sending data.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      dialogVisible,
      handleClose,
      needID,
      fetchProjectNeeds,
      projectNeeds,
      loading,
      discountPercentageArray,
      selectProjectNeed,
      discountPercentage,
      projectBudget,
      message,
      submitInvest,
    };
  },
};
</script>
<style>
.el-form-style {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
</style>
