<template>
  <div class="needs-container">
    <h1
      class="primary-text fw-boldest d-inline-block pb-3"
      style="border-bottom: 2px solid #d5dfe5"
    >
      Needs
    </h1>
    <div class="table-responsive">
      <table class="table align-middle gs-0 gy-3 mb-0" v-loading="loading">
        <thead>
          <tr class="fw-bolder text-muted">
            <th class="min-w-140px">Name</th>
            <th class="min-w-140px">Key Skill</th>
            <th class="min-w-140px">Priority</th>
            <th class="min-w-140px">Budget</th>
            <th class="min-w-140px">Start</th>
            <th class="min-w-140px">Deliver</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(projectNeed, index) in projectNeeds" :key="index">
            <tr>
              <td class="px-10">
                <a href="#" class="primary-text text-hover-primary fs-6">{{
                  projectNeed.name ? projectNeed.name : ""
                }}</a>
              </td>

              <td class="px-10">
                <a href="#" class="primary-text text-hover-primary fs-6">{{
                  projectNeed.skills ? projectNeed.skills.name : ""
                }}</a>
              </td>

              <td class="px-10">
                <span
                  class="badge"
                  :class="
                    projectNeed.priorityLevel
                      ? getPriorityBadgeColor(projectNeed.priorityLevel.name)
                      : 'badge-light'
                  "
                  >{{
                    projectNeed.priorityLevel
                      ? projectNeed.priorityLevel.name
                      : ""
                  }}</span
                >
              </td>

              <td class="px-10">
                <a href="#" class="primary-text text-hover-primary fs-6">{{
                  projectNeed.budget
                    ? "€" + projectNeed.budget.toLocaleString()
                    : ""
                }}</a>
              </td>

              <td class="primary-text text-hover-primary fs-6 px-10">
                <a href="#" class="primary-text text-hover-primary fs-6">{{
                  projectNeed.startDate
                    ? new Date(projectNeed.startDate).toDateString()
                    : ""
                }}</a>
              </td>

              <td class="px-10">
                <a href="#" class="primary-text text-hover-primary fs-6">{{
                  projectNeed.endDate
                    ? new Date(projectNeed.endDate).toDateString()
                    : ""
                }}</a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { ElNotification } from "element-plus";
import { getAllProjectNeedsOfProject } from "@/api/founder.api";

export default {
  name: "PublicPitchNeeds",
  props: {
    founderId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const projectNeeds = ref([]);
    const loading = ref(false);

    onMounted(async () => {
      try {
        loading.value = true;
        await fetchProjectNeeds();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error fetching data.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    });

    const fetchProjectNeeds = async () => {
      const { data } = await getAllProjectNeedsOfProject(props.founderId);
      projectNeeds.value = data.data;
    };

    const getPriorityBadgeColor = (priorityLevel) => {
      if (priorityLevel === "Must Have") {
        return "badge-primary";
      } else if (priorityLevel === "Important") {
        return "badge-info";
      } else if (priorityLevel === "Nice to Have") {
        return "badge-success";
      } else {
        return "badge-warning";
      }
    };

    return { projectNeeds, loading, getPriorityBadgeColor };
  },
};
</script>

<style scoped lang="scss">
.needs-container {
  //margin-top: 3rem;
  margin-left: 1rem;
}
.px-10 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  & {
    border-bottom: 1px solid #d5dfe5;
  }
}
</style>
