<template>
  <div class="deal-terms-container" v-loading="loading">
    <h1
      class="primary-text fw-boldest d-inline-block pb-3"
      style="border-bottom: 2px solid #d5dfe5"
    >
      Deal terms
    </h1>
    <div class="deal-term deal-term-border">
      <div class="d-flex justify-content-between">
        <span style="color: #b8c9d3; font-size: 1.1em"
          >Basis for Revenue Share</span
        >
        <el-icon style="color: #b8c9d3" :size="18"><Warning /></el-icon>
      </div>
      <h3 class="primary-text mt-2">
        {{
          projectDealTerms.revenueShare
            ? projectDealTerms.revenueShare.name
            : "N/A"
        }}
      </h3>
    </div>
    <div class="deal-term deal-term-border">
      <div class="d-flex justify-content-between">
        <span style="color: #b8c9d3; font-size: 1.1em">Revenue Share</span>
        <el-icon style="color: #b8c9d3" :size="18"><Warning /></el-icon>
      </div>
      <h3 class="primary-text mt-2">
        {{
          projectDealTerms.percentageRevenueShare
            ? `${projectDealTerms.percentageRevenueShare}%`
            : "N/A"
        }}
      </h3>
    </div>
    <div class="deal-term deal-term-border">
      <div class="d-flex justify-content-between">
        <span style="color: #b8c9d3; font-size: 1.1em">Threshold</span>
        <el-icon style="color: #b8c9d3" :size="18"><Warning /></el-icon>
      </div>
      <h3 class="primary-text mt-2">
        {{
          projectDealTerms.thresholdSubItem &&
          projectDealTerms.thresholdSubItem.value
            ? "€" + projectDealTerms.thresholdSubItem.value.toLocaleString()
            : "N/A"
        }}
      </h3>
    </div>
    <div class="deal-term deal-term-border">
      <div class="d-flex justify-content-between">
        <span style="color: #b8c9d3; font-size: 1.1em">Equity Share</span>
        <el-icon style="color: #b8c9d3" :size="18"><Warning /></el-icon>
      </div>
      <h3 class="primary-text mt-2">
        {{
          projectDealTerms.equityShare
            ? `${projectDealTerms.equityShare}%`
            : "N/A"
        }}
      </h3>
    </div>
    <div class="deal-term deal-term-border">
      <div class="d-flex justify-content-between">
        <span style="color: #b8c9d3; font-size: 1.1em">Type of Equity</span>
        <el-icon style="color: #b8c9d3" :size="18"><Warning /></el-icon>
      </div>
      <h3 class="primary-text mt-2">
        {{
          projectDealTerms.thresholdSubItem
            ? projectDealTerms.thresholdSubItem.thresholdItem
              ? projectDealTerms.thresholdSubItem.thresholdItem.name
              : "N/A"
            : "N/A"
        }}
      </h3>
    </div>
    <!--    <div class="deal-term deal-term-border">-->
    <!--      <div class="d-flex justify-content-between">-->
    <!--        <span style="color: #b8c9d3; font-size: 1.1em">Offered Valuation</span>-->
    <!--        <el-icon style="color: #b8c9d3" :size="18"><Warning /></el-icon>-->
    <!--      </div>-->
    <!--      <h3 class="primary-text mt-2">$ 195,000,000</h3>-->
    <!--    </div>-->
    <!--    <el-button class="w-100 primary-text mt-5 mb-0">How it works</el-button>-->
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Warning } from "@element-plus/icons-vue";
// import { ElNotification } from "element-plus";
import { getProjectDealTerms } from "@/api/founder.api";
export default {
  components: { Warning },
  name: "PublicPitchDealTerms",
  props: {
    founderId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const projectDealTerms = ref({
      equityShare: null,
      percentageRevenueShare: null,
      revenueShare: null,
      thresholdSubItem: null,
      thresholdValue: null,
    });
    const loading = ref(false);

    onMounted(async () => {
      try {
        loading.value = true;
        await fetchDealTerms();
      } catch (e) {
        // ElNotification({
        //   title: "Error",
        //   message: "Error fetching data.",
        //   type: "error",
        // });
      } finally {
        loading.value = false;
      }
    });

    const fetchDealTerms = async () => {
      const { data } = await getProjectDealTerms(props.founderId);
      projectDealTerms.value.equityShare = data.data.equityShare;
      projectDealTerms.value.revenueShare = data.data.revenueShare;
      projectDealTerms.value.percentageRevenueShare =
        data.data.percentageRevenueShare;
      projectDealTerms.value.thresholdValue = data.data.equityShare;
      projectDealTerms.value.thresholdSubItem = data.data.thresholdSubItem;
    };

    return { projectDealTerms, loading };
  },
};
</script>

<style lang="scss" scoped>
.deal-terms-container {
  //margin-top: 3rem;
  margin-left: 1rem;
  //overflow: scroll;
  .deal-term-border:not(:nth-last-of-type(1)) {
    border-bottom: 1px solid #d5dfe5;
  }
  .deal-term-border:nth-last-of-type(1) {
    padding-bottom: 0;
  }
  .deal-term {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
