<template>
  <el-row class="public-pitch-header" :gutter="40" v-loading="loading">
    <el-col :span="18">
      <div class="d-flex align-items-center">
        <img
          v-if="companyLogoAndImage.logo"
          style="height: 60px; border-radius: 5px; margin-right: 1rem"
          :src="companyLogoAndImage.logo"
        />
        <h1 class="primary-text company-name d-inline-block">
          {{ companyName }}
        </h1>
      </div>
      <p class="subline mt-2">
        A subline of text, which may not break and be no longer than width of
        image below.
      </p>
      <span
        v-for="(item, index) in companyInfo"
        :key="index"
        class="badge badge-secondary fs-6"
        style="margin-right: 0.5rem"
        >{{ item }}</span
      >
    </el-col>
    <el-col :span="6">
      <div class="d-flex justify-content-end">
        <el-icon style="color: #7d7d7d" class="star-icon" :size="35"
          ><Star
        /></el-icon>
        <el-icon style="color: #7d7d7d" :size="35"><Upload /></el-icon>
      </div>
    </el-col>
    <!--    <el-col :span="18" class="mt-4">-->
    <!--      <el-image-->
    <!--        v-if="companyLogoAndImage.teaserImage"-->
    <!--        style="border-radius: 10px; width: 100%; height: 100%"-->
    <!--        :src="companyLogoAndImage.teaserImage"-->
    <!--      ></el-image>-->
    <!--      <div-->
    <!--        v-else-->
    <!--        class="d-flex justify-content-center align-items-center w-100 h-100"-->
    <!--      >-->
    <!--        <h4 class="primary-text">This user hasn't not added teaser image</h4>-->
    <!--      </div>-->
    <!--    </el-col>-->
    <!--    <el-col style="height: 30rem" class="mt-4" :span="6">-->
    <!--      <div class="d-flex flex-column justify-content-between h-100">-->
    <!--        <div>-->
    <!--          <div class="mb-8">-->
    <!--            <h3 class="stat-heading">$0</h3>-->
    <!--            <p class="stat-description">3'008% of minimum goal raised</p>-->
    <!--          </div>-->
    <!--          <div class="mb-8">-->
    <!--            <h3 class="stat-heading">0</h3>-->
    <!--            <p class="stat-description">Investors</p>-->
    <!--          </div>-->
    <!--          <div class="mb-8">-->
    <!--            <h3 class="stat-heading">90 days</h3>-->
    <!--            <p class="stat-description">Left to invest</p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <el-button class="w-100" type="primary"-->
    <!--          >Invest in {{ companyName }}</el-button-->
    <!--        >-->
    <!--      </div>-->
    <!--    </el-col>-->
  </el-row>
</template>

<script>
import { Star, Upload } from "@element-plus/icons-vue";
import { onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import {
  getBusinessModelsOfFounder,
  getCustomerGroupsOfFounder,
  getFounderCompanyName,
  getIndustriesOfFounder,
} from "@/api/founder.api";
export default {
  components: { Star, Upload },
  name: "PublicPitchHeader",
  props: {
    founderId: {
      type: Number,
      required: true,
    },
    companyLogoAndImage: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const companyName = ref("");
    const loading = ref(false);
    // const companyLogoAndImage = ref({
    //   logo: "",
    //   teaserImage: "",
    // });
    const companyInfo = ref([]);
    onMounted(async () => {
      await fetchAllData();
    });

    const fetchAllData = async () => {
      try {
        loading.value = true;
        const promises = [];
        promises.push(
          new Promise((resolve) => {
            fetchCompanyName()
              .then(() => resolve(""))
              .catch(() => resolve(""));
          })
        );
        promises.push(
          new Promise((resolve) => {
            fetchFounderBusinessModels()
              .then(() => resolve(""))
              .catch(() => resolve(""));
          })
        );
        promises.push(
          new Promise((resolve) => {
            fetchFounderCustomerGroups()
              .then(() => resolve(""))
              .catch(() => resolve(""));
          })
        );
        promises.push(
          new Promise((resolve) => {
            fetchFounderIndustries()
              .then(() => resolve(""))
              .catch(() => resolve(""));
          })
        );
        await Promise.all(promises);
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error fetching data.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const fetchCompanyName = async () => {
      const { data } = await getFounderCompanyName(props.founderId);
      companyName.value = data.data.companyName;
      companyInfo.value.push(data.data.projectStatus.name);
    };

    const fetchFounderBusinessModels = async () => {
      const { data } = await getBusinessModelsOfFounder(props.founderId);
      for (const obj of data.data) {
        companyInfo.value.push(obj.businessModel.name);
      }
    };

    const fetchFounderCustomerGroups = async () => {
      const { data } = await getCustomerGroupsOfFounder(props.founderId);
      for (const obj of data.data) {
        companyInfo.value.push(obj.customerGroup.name);
      }
    };

    const fetchFounderIndustries = async () => {
      const { data } = await getIndustriesOfFounder(props.founderId);
      for (const obj of data.data) {
        companyInfo.value.push(obj.industry.name);
      }
    };

    return { companyName, loading, companyInfo };
  },
};
</script>

<style lang="scss">
.public-pitch-header {
  .el-avatar > img {
    width: 100% !important;
  }
  .company-name {
    font-size: 3em;
    font-weight: bolder;
  }
  .subline {
    font-size: 1.25em;
    color: #a0b7c3;
  }
  .star-icon {
    margin-right: 1rem;
  }
  .stat-heading {
    color: #4dc75f;
    font-size: 3em;
  }
  .stat-description {
    color: #a0b7c3;
  }
}
</style>
