<template>
  <div class="needs-container" v-loading="loading">
    <h1
      class="primary-text fw-boldest mb-5 d-inline-block pb-3"
      style="border-bottom: 2px solid #d5dfe5"
    >
      Share of Needs
    </h1>
    <div
      v-for="(item, index) in projectNeedsWithPercentage"
      :key="index"
      class="mb-10"
    >
      <div class="primary-text d-flex justify-content-between">
        <span class="primary-text fs-6">{{ item.name }}</span>
        <span>{{ item.share ? item.share.toFixed(2) : 0 }} %</span>
      </div>
      <el-progress class="mt-1" :percentage="item.share" :show-text="false" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import { getProjectNeedsWithPercentage } from "@/api/founder.api";

export default {
  name: "PublicPitchNeedShare",
  props: {
    founderId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const projectNeedsWithPercentage = ref([]);
    const loading = ref(false);

    onMounted(async () => {
      try {
        loading.value = true;
        await fetchProjectNeedsWithPercentage();
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error fetching data.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    });

    const fetchProjectNeedsWithPercentage = async () => {
      const { data } = await getProjectNeedsWithPercentage(props.founderId);
      projectNeedsWithPercentage.value = data.data;
    };

    return { projectNeedsWithPercentage, loading };
  },
};
</script>

<style scoped lang="scss">
.needs-container {
  //margin-top: 3rem;
  margin-left: 1rem;
}
</style>
