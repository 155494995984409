<template>
  <div
    id="founderProfileDrawer"
    class="bg-white"
    data-kt-drawer="true"
    data-kt-drawer-name="explore"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'90%'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_explore_toggle"
    data-kt-drawer-close="#kt_explore_close"
  >
    <div class="card shadow-none w-100">
      <div class="card-header" id="kt_explore_header">
        <div class="d-flex justify-content-end align-items-center w-100">
          <el-icon
            id="kt_explore_close"
            class="cursor-pointer close-icon"
            :size="20"
            ><CloseBold
          /></el-icon>
        </div>
      </div>
      <div
        class="card-body"
        style="padding: 40px !important"
        id="kt_explore_body"
      >
        <div>
          <PublicPitchHeader
            v-if="drawerShown"
            :founder-id="founderId"
            :company-logo-and-image="companyLogoAndImage"
          />
          <!--          <PublicPitchNavigation v-if="drawerShown" class="mt-10" />-->
          <PublicPitch v-if="drawerShown" :founder-id="founderId" />
        </div>
        <!--        <div-->
        <!--          id="kt_explore_scroll"-->
        <!--          class="scroll-y me-n5 pe-5"-->
        <!--          data-kt-scroll="true"-->
        <!--          data-kt-scroll-height="auto"-->
        <!--          data-kt-scroll-wrappers="#kt_explore_body"-->
        <!--          data-kt-scroll-dependencies="#kt_explore_header, #kt_explore_footer"-->
        <!--          data-kt-scroll-offset="5px"-->
        <!--        >-->
        <!--          -->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import PublicPitchHeader from "@/founder/components/PublicPitchHeader.vue";
import { CloseBold } from "@element-plus/icons-vue";
// import PublicPitchNavigation from "@/founder/components/PublicPitchNavigation.vue";
import PublicPitch from "@/founder/components/PublicPitch.vue";
import { DrawerComponent } from "@/assets/ts/components";
import { ElNotification } from "element-plus";
import { getFounderCompanyName, getLogos } from "@/api/founder.api";

export default defineComponent({
  name: "PublicPitchDrawer",
  components: {
    PublicPitch,
    // PublicPitchNavigation,
    CloseBold,
    PublicPitchHeader,
  },
  setup() {
    const drawerShown = ref(false);

    const founderId = ref(null);
    const companyName = ref("");
    const loading = ref(false);
    const companyLogoAndImage = ref({
      logo: "",
      teaserImage: "",
    });
    const companyInfo = ref([]);
    const fetchAllData = async () => {
      try {
        loading.value = true;
        const promises = [];
        promises.push(
          new Promise((resolve) => {
            fetchCompanyName()
              .then(() => resolve(""))
              .catch(() => resolve(""));
          })
        );
        promises.push(
          new Promise((resolve) => {
            fetchLogos()
              .then(() => resolve(""))
              .catch(() => resolve(""));
          })
        );
        await Promise.all(promises);
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error fetching data.",
          type: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const fetchCompanyName = async () => {
      const { data } = await getFounderCompanyName(founderId.value);
      companyName.value = data.data.companyName;
      companyInfo.value.push(data.data.projectStatus.name);
    };

    const fetchLogos = async () => {
      const { data } = await getLogos(founderId.value);
      companyLogoAndImage.value.logo = data.data.blackLogo
        ? data.data.blackLogo
        : data.data.originalLogo;
      companyLogoAndImage.value.teaserImage = data.data.teaserImage;
    };

    const showDrawer = (founderIdParam) => {
      drawerShown.value = false;
      drawerShown.value = true;
      DrawerComponent.getInstance("founderProfileDrawer").show();
      founderId.value = founderIdParam;
      fetchAllData();
    };
    const hideDrawer = () => {
      drawerShown.value = false;
      founderId.value = null;
      DrawerComponent.getInstance("founderProfileDrawer").hide();
    };

    onMounted(() => {
      setTimeout(() => {
        const drawer = DrawerComponent.getInstance("founderProfileDrawer");
        drawer.on("kt.drawer.after.hidden", function () {
          drawerShown.value = false;
          founderId.value = null;
        });
      }, 500);
    });

    return {
      showDrawer,
      hideDrawer,
      founderId,
      drawerShown,
      companyLogoAndImage,
    };
  },
});
</script>
<style lang="scss" scoped>
.close-icon {
  color: #a0b7c3;
}
</style>
