<template>
  <div class="document-viewer-container" v-loading="loading">
    <div class="d-flex justify-content-between align-items-center">
      <h1
        class="primary-text fw-boldest d-inline-block pb-3"
        style="border-bottom: 2px solid #d5dfe5"
      >
        Pitch
      </h1>
      <el-button v-if="pitchFile" @click="viewFile" type="primary"
        >View</el-button
      >
    </div>
    <!--    <div class="pdf-container mt-5">-->
    <!--      <pdf-->
    <!--        v-for="page in numOfPages"-->
    <!--        :key="page"-->
    <!--        :page="page + 1"-->
    <!--        src="pitch.pdf"-->
    <!--        @numpages="showPages"-->
    <!--      />-->
    <!--    </div>-->
    <el-carousel
      indicator-position="outside"
      v-if="pitchFile"
      :loop="false"
      :autoplay="false"
      trigger="click"
      :height="`${height}px`"
    >
      <el-carousel-item v-for="page in numOfPages" :key="page">
        <pdf
          @loaded="dataLoaded"
          @loading="documentLoading"
          @numpages="showPages"
          ref="pdfViewer"
          :page="page"
          :src="pitchFile"
        />
      </el-carousel-item>
    </el-carousel>
    <div
      style="height: 10rem"
      v-else
      class="d-flex justify-content-center align-items-center w-100"
    >
      <h4 class="primary-text">This user hasn't not added pitch document</h4>
    </div>
  </div>
</template>

<script>
import pdf from "pdfvuer";
import "pdfjs-dist/build/pdf.worker.entry";
import { onMounted, ref, watch } from "vue";
export default {
  components: { pdf },
  name: "PublicPitchDocumentViewer",
  props: {
    founderId: {
      type: Number,
      required: true,
    },
    pitchFile: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const pdfViewer = ref(null);
    const loading = ref(false);
    const height = ref(600);
    const pdfLoadedFlag = ref(false);

    const numOfPages = ref(1);
    const showPages = (pages) => {
      numOfPages.value = pages;
    };

    const dataLoaded = (value) => {
      console.log(value);
    };

    const documentLoading = (value) => {
      if (!value) {
        height.value = pdfViewer.value.$refs.container.offsetHeight;
        if (!pdfLoadedFlag.value) {
          pdfLoadedFlag.value = true;
          setTimeout(() => {
            loading.value = false;
          }, 2000);
        }
      }
    };

    const viewFile = () => {
      window.open(props.pitchFile, "_blank");
    };

    watch(
      () => props.pitchFile,
      (value) => {
        if (value) {
          loading.value = true;
        }
      },
      { immediate: true }
    );

    return {
      numOfPages,
      loading,
      dataLoaded,
      showPages,
      viewFile,
      documentLoading,
      pdfViewer,
      height,
    };
  },
};
</script>

<style lang="scss">
.document-viewer-container {
  .el-carousel {
    .el-carousel__indicators {
      .el-carousel__button {
        background-color: #409eff;
      }
    }
  }
  //margin-top: 3rem;
  margin-left: 1rem;
  .pdf-container {
    //height: 30rem;
    //overflow: scroll;
  }
}
</style>
