<template>
  <div class="document-container">
    <h1
      class="primary-text fw-boldest d-inline-block pb-3"
      style="border-bottom: 2px solid #d5dfe5"
    >
      Documents
    </h1>
    <div class="files-container mt-5">
      <div class="paragraph">
        <p style="color: #d1dce2">
          All documents have been provided by the company and are available for
          review. 7Founders has reviewed their content but not checked for
          correctness and completion
        </p>
      </div>
      <template v-for="(document, index) in documents" :key="index">
        <div v-if="document.file" class="d-flex align-items-center mt-5">
          <span class="svg-icon svg-icon-1 svg-icon-primary plus-icon">
            <img
              class="h-75 w-75"
              :src="getFileTypeUrlFromFile(document.type)"
              alt="logo"
            />
          </span>
          <h3
            @click="viewFile(document.file)"
            v-if="document.file"
            class="text-hover-primary cursor-pointer"
          >
            {{ document.name }}
          </h3>
          <h3 v-else class="primary-text cursor-disabled">
            {{ document.name }}
          </h3>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import { getAllRequiredUploads } from "@/api/founder.api";
import { getFileTypeUrlFromFile } from "@/common/helpers/helpers";

export default {
  name: "PublicPitchDocuments",
  props: {
    founderId: {
      type: Number,
      required: true,
    },
    documents: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(true);

    // onMounted(async () => {
    //   try {
    //     loading.value = true;
    //     await fetchDocuments();
    //   } catch (e) {
    //     ElNotification({
    //       title: "Error",
    //       message: "Error fetching data.",
    //       type: "error",
    //     });
    //   } finally {
    //     loading.value = false;
    //   }
    // });

    const viewFile = (file) => {
      window.open(file, "_blank");
    };

    // const fetchDocuments = async () => {
    //   const { data } = await getAllRequiredUploads(props.founderId);
    //   documents.value.push({
    //     type: "venturePitch",
    //     file: data.data.venturePitch,
    //     name: "Venture Pitch",
    //   });
    //   documents.value.push({
    //     type: "financialPlan",
    //     file: data.data.financialPlan,
    //     name: "Financial Plan",
    //   });
    //   documents.value.push({
    //     type: "sevenFoundersForecast",
    //     file: data.data.sevenFoundersForecast,
    //     name: "Forecast",
    //   });
    //   documents.value.push({
    //     type: "dataRequest",
    //     file: data.data.dataRequest,
    //     name: "Data Request",
    //   });
    // };

    return { loading, viewFile, getFileTypeUrlFromFile };
  },
};
</script>

<style scoped lang="scss">
.document-container {
  //margin-top: 3rem;
  margin-left: 1rem;
  .files-container {
    border: 1px solid #d5dfe5;
    border-radius: 5px;
    padding: 1rem;
    //height: 30rem;
    overflow: scroll;
    .paragraph {
      border-bottom: 1px solid #d5dfe5;
    }
  }
}
</style>
